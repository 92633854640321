<template>
  <div v-if="menuCategory.isCategory">
    <template v-if="isCollapsed">
      <el-popover
        trigger="click"
        placement="right"
        width="250"
        popper-class="m-menu"
      >
        <el-menu-item
          slot="reference"
          class="m-menu-item m-menu-item-category"
          disabled
        >
          <span
            class="m-menu-item__label"
            :class="{
              primary: isActive
            }"
          >
            {{ menuCategory.label }}
          </span>
        </el-menu-item>

        <m-menu-item
          v-for="menuItem in menuCategory.items"
          :key="menuItem.name"
          :menu-item="menuItem"
          :is-nested-page="isNestedPage"
          show-label
        />
      </el-popover>
    </template>
    <template v-else>
      <el-menu-item
        class="m-menu-item m-menu-item-category"
        disabled
      >
        <span class="m-menu-item__label">{{ menuCategory.label }}</span>
      </el-menu-item>
      <m-menu-item
        v-for="menuItem in menuCategory.items"
        :key="menuItem.name"
        :menu-item="menuItem"
        :show-label="showLabel"
        :vertical="menuCategory.vertical"
        :is-nested-page="isNestedPage"
      />
    </template>
  </div>
  <div v-else>
    <m-menu-item
      v-for="menuItem in menuCategory.items"
      :key="menuItem.name"
      :menu-item="menuItem"
      :show-label="showLabel"
      :vertical="menuCategory.vertical"
      :is-nested-page="isNestedPage"
    />
  </div>
</template>

<script>
import MMenuItem from '@/vue_present/_base/MMenu/MMenuItem'

export default {
  name: 'MMenuCategory',

  components: { MMenuItem },

  props: {
    isCollapsed: Boolean,
    showLabel: Boolean,

    menuCategory: {
      type: Object,
      required: true,
    },
  },

  computed: {
    routeNames () {
      return this.menuCategory.items.map((item) => item.name)
    },

    isNestedPage () {
      const nestedRoutes = this.menuCategory.nestedRoutes

      return Boolean(nestedRoutes) && nestedRoutes.includes(this.$route.name)
    },

    isActive () {
      return this.routeNames.includes(this.$route.name) || this.isNestedPage
    },
  },
}
</script>
