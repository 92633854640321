import { Api } from '@/_api/decorators/api/Api'
import { ICRUDReturn } from '@/_api/decorators/api/interfaces/ICRUDReturn'
import { Callback } from '@/_api/decorators/callback'
import {
  workplacesConfigurationAdapter,
} from '@/vue_apps/app_configuration/workplaces/api/workplacesConfigurationAdapter'
import {
  WorkplacesConfigurationModel,
} from '@/vue_apps/app_configuration/workplaces/classes/WorkplacesConfigurationModel'

export class WorkplacesConfigurationApi extends WorkplacesConfigurationModel {
  @Callback('_fillProps')
  @Api.notify('fetchMessage', 'workplaceConfiguration', { success: false })
  @Api.clientAdapter(workplacesConfigurationAdapter)
  @Api.get(Routes.api_internal_configurations_cash_registers_path)
  fetchConfiguration () {}

  @Api.notify('submitMessage', 'configurations')
  @Api.post(Routes.app_configuration_path, { toFormData: true })
  @Api.serverAdapter(workplacesConfigurationAdapter)
  submit () {
    return {
      routeArgs: [gon.page.params.id],
      payload: this.getAttributes(),
    } as ICRUDReturn as Promise<void>
  }
}
