import NetworkModule from '@/vue_apps/app_configuration/network/NetworkModule.vue'
import { createVueApp } from '@/vue_components/create_vue_app'
import { checkDemoMedBan } from '@/vue_apps/app_configuration/network/const/checkDemoMedBan'

let vueApp = null

export const createNetworkModuleVue = (el = '#network_page_vue') => {
  if (vueApp?.$destroy) { vueApp.$destroy() }

  vueApp = createVueApp({
    el,
    name: 'NetworkModule',
    render: (h) => h(NetworkModule, {
      props: {
        moduleIsEnabledDefault: gon.specific.whiteListIsEnabled,
        isModuleAllowed: !checkDemoMedBan(gon.application.current_user.username),
        configurationLimitByIp: gon.application.configuration_limit_by_ip,
        ipCheckInterval: gon.application.ipCheckInterval,
      },
    }),
  })
}

$document.on('rez/app_configurations/edit', () => createNetworkModuleVue())
