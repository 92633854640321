import { createVueApp } from '@/vue_components/create_vue_app'
import WorkplacesConfigurationApp from '@/vue_apps/app_configuration/workplaces/WorkplacesConfigurationApp.vue'

let vueApp = null
const createWorkplacesConfigurationApp = (el = '#vue_workplaces_configuration_app') => {
  if (vueApp?.$destroy) { vueApp.$destroy() }

  vueApp = createVueApp({
    el,
    name: 'WorkplacesConfigurationAppRoot',
    render: (h) => h(WorkplacesConfigurationApp),
  })
}

$document.on('rez/app_configurations/edit', () => createWorkplacesConfigurationApp())
