<template>
  <m-panel
    class="measure-units__list-wrapper"
    :title="t('measureUnits.titlePlural')"
    :use-type-by-route="false"
    icon="measureUnits"
  >
    <m-si-generator
      :items="list.data"
      :si-generator-schema="schema"
      :page-count="list.totalPages"
      :current-page="list.currentPage"
      :add-button-config="addButtonConfig"
      :active-item-id="activeItemId"
      @update:searchQuery="list.search($event)"
      @update:currentPage="list.setPage($event)"
      @onItemClick="onItemClick"
      @onAddItem="onAddItem"
    />
  </m-panel>
</template>

<script lang="ts">
import MSiGenerator from '@/vue_present/_base/Tables/MSiGenerator/MSiGenerator.vue'
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import { MListServiceApi } from '@/_api/_requests/MListServiceApi/MListServiceApi'
import { MEASURE_UNITS_ROUTES_NAMES } from '../routes/measureUnitsRoutesNames'
import { getMeasureUnitsListAddButtonConfig, getMeasureUnitsListSchema } from '../const/measureUnitsListSchema'
import { defineComponent } from 'vue'
import { MeasureUnitsListItem } from '../entities/MeasureUnitsListItem'

export default defineComponent({
  name: 'MeasureUnitsList',

  components: {
    MSiGenerator,
    MPanel,
  },

  props: {
    list: { type: MListServiceApi, required: true },
  },

  data () {
    return {
      schema: getMeasureUnitsListSchema(),
      addButtonConfig: getMeasureUnitsListAddButtonConfig(),
    }
  },

  computed: {
    activeItemId (): number | null {
      return +this.$route.params.id || null
    },
  },

  methods: {
    onItemClick ({ id }: MeasureUnitsListItem) {
      if (id === +this.$route.params.id) { return }

      this.$router.push({
        name: MEASURE_UNITS_ROUTES_NAMES.EDIT,
        params: { id },
      })
    },

    onAddItem () {
      if (this.$route.name === MEASURE_UNITS_ROUTES_NAMES.NEW) { return }

      this.$router.push({ name: MEASURE_UNITS_ROUTES_NAMES.NEW })
    },
  },
})
</script>
