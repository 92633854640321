import FastAddSwitches from './FastAddSwitches.vue'
import Vue from 'vue/dist/vue.esm'

PubSub.on('createFastAddSwitches', (msg, { parentRow, ...restProps }) => {
  const el = parentRow.querySelector('.fast-add-switches-vue')

  if (!el) { return }

  //eslint-disable-next-line
  new Vue({
    el,
    name: 'FastAddSwitchesRoot',
    render: (h) => h(FastAddSwitches, {
      props: { parentRow, ...restProps },
    }),
  })
})
