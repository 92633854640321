<template>
  <div class="workplaces-configuration">
    <div class="flex gap-indent-mid align-items-center">
      <m-icon
        icon="warning"
        color="warning"
        size="32px"
      />

      <b v-html="t('fiscal.taxRateWarning')" />
    </div>

    <div class="flex align-items-center gap-indent-small">
      <m-switch
        :value="workplacesConfiguration.printReceiptWithComplexMembers"
        :active-text="t('configuration.print_receipt_with_complex_members')"
        @change="workplacesConfiguration.setValue('printReceiptWithComplexMembers', $event)"
      />

      <m-icon
        v-tooltip="t('fiscal.printComplexMembersSettingExplanation')"
        icon="questionCircle"
        color="grey"
        use-solid
      />
    </div>

    <m-button
      template="save"
      @click="workplacesConfiguration.submit()"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { WorkplacesConfigurationApi } from '@/vue_apps/app_configuration/workplaces/classes/WorkplacesConfigurationApi'
import MSwitch from '@/vue_present/_base/inputs/MSwitch/MSwitch.vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'

export default defineComponent({
  name: 'WorkplacesConfigurationApp',

  components: {
    MIcon,
    MSwitch,
    MButton,
  },

  data () {
    return {
      workplacesConfiguration: new WorkplacesConfigurationApi(),
    }
  },

  created () {
    this.workplacesConfiguration.fetchConfiguration()
  },
})
</script>
