import { createVueApp } from '@/vue_components/create_vue_app'
import MeasureUnits from './MeasureUnits.vue'

let vueApp = null

const createMeasureUnitsApp = (el = '#vue_measure_units_catalog_root') => {
  if (vueApp) { vueApp?.$destroy() }
  if (!document.querySelector(el)) { return }

  vueApp = createVueApp({
    el,
    name: 'MeasureUnitsRoot',
    render: (h) => h(MeasureUnits),
  })
}

$document.on('rez/measure_units', () => createMeasureUnitsApp())
