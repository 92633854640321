<template>
  <div class="measure-units">
    <measure-units-list :list="list" />

    <router-view
      @onSubmit="fetchList"
      @onDelete="fetchList"
    />
  </div>
</template>

<script lang="ts">
import MeasureUnitsList from './components/MeasureUnitsList.vue'
import { MListServiceApi } from '@/_api/_requests/MListServiceApi/MListServiceApi'
import { MeasureUnitsListItem } from './entities/MeasureUnitsListItem'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MeasureUnits',

  components: {
    MeasureUnitsList,
  },

  data () {
    return {
      list: new MListServiceApi({
        defaultFilters: {},
        src: Routes.list_api_internal_catalogs_measure_units_path(),
        DataItemEntity: MeasureUnitsListItem,
      }),
    }
  },

  mounted () {
    this.fetchList()
  },

  methods: {
    fetchList () {
      this.list.fetchAll()
    },
  },
})
</script>
