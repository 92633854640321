import { Api } from '@/_api/decorators/api/Api'
import { ICRUDReturn } from '@/_api/decorators/api/interfaces/ICRUDReturn'
import { IAppConfigurationRaw } from '@/_declarations/IAppConfigurationRaw'
import { IApiErrors } from '@/_declarations/IApiErrors'

export class MAppConfigurationAPI {
  @Api.notify('', '', { success: false, failure: false })
  @Api.patch(Routes.app_configuration_path)
  static update (appConfiguration: Record<string, string | number | boolean>) {
    return {
      routeArgs: [1],
      payload: {
        appConfiguration,
      },
    } as ICRUDReturn as Promise<IAppConfigurationRaw | IApiErrors>
  }
}
