import { ItemBase } from '@/_api/_classes/ItemBase'
import { orNull } from '@/_declarations/orNull'
import { ICatalog } from '@/_declarations/ICatalog'
import {
  TRADE_ACQUIRING_CONFIGURATION_BANK,
} from '@/vue_apps/app_configuration/tradeAcquiring/consts/tradeAcquiringConfigurationBank'

export class TradeAcquiringConfigurationModel extends ItemBase {
  _permit = [
    'reconciliationTheResultsWithZMainReport',
    'notPrintReceiptForSuccessfulAcquiringOperations',
    'autoPrintingOfTradeAcquiringReceipts',
    'tradeAcquiringBank',
  ]

  reconciliationTheResultsWithZMainReport: boolean = true

  notPrintReceiptForSuccessfulAcquiringOperations: boolean = true

  autoPrintingOfTradeAcquiringReceipts: boolean = true

  tradeAcquiringBank: orNull<ICatalog<TRADE_ACQUIRING_CONFIGURATION_BANK>> = null
}
