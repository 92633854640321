<template>
  <div class="fast-add-switches flex align-center mr-5">
    <m-switch
      v-tooltip="t('send_message_on_ready_status_check')"
      class="mr-5"
      :value="sendMessageValue"
      :disabled="isDisabled"
      @change="onSendMessageChange"
    />

    <m-switch
      v-tooltip="t('entry_online_access_check')"
      :value="onlineAccessValue"
      :disabled="isDisabled"
      @change="onOnlineAccessChange"
    />
  </div>
</template>

<script>
import MSwitch from '@/vue_present/_base/inputs/MSwitch/MSwitch.vue'

const CATEGORY_KIND = '-1'
const GOOD_KIND = '5'

export default {
  name: 'FastAddSwitches',

  components: { MSwitch },

  props: {
    parentRow: { type: HTMLDivElement, required: true },
    initialValues: { type: Object, default: null },
    isInitiallyDisabled: Boolean,
  },

  data () {
    return {
      sendMessageValue: false,
      onlineAccessValue: true,
      isDisabled: false,
    }
  },

  mounted () {
    if (this.initialValues) {
      this.sendMessageValue = this.initialValues.sendMessage
      this.onlineAccessValue = this.initialValues.onlineAccess
    }

    if (this.isInitiallyDisabled) {
      this.isDisabled = true
    }

    this.getKindSelect().addEventListener('change', this.onKindChange)
  },

  beforeDestroy () {
    this.getKindSelect().removeEventListener('change', this.onKindChange)
  },

  methods: {
    getKindSelect () {
      return this.parentRow.querySelector('.f--kind')
    },

    onKindChange ({ target: { value } }) {
      value === CATEGORY_KIND
        ? this.$destroy()
        : this.disableIfNeed(value)
    },

    disableIfNeed (value) {
      value === GOOD_KIND
        ? this.isDisabled = true
        : this.isDisabled = false
    },

    updateHiddenSelect (hiddenSelectClass, isAllowed) {
      const hiddenSelect = this.parentRow.querySelector(`.${hiddenSelectClass} .form-control`)

      const newSelectValue = isAllowed
        ? 'allowed'
        : 'prohibited'

      hiddenSelect.value = newSelectValue
    },

    onSendMessageChange (value) {
      this.sendMessageValue = value
      this.updateHiddenSelect('ff-send_message_on_ready_status', value)
    },

    onOnlineAccessChange (value) {
      this.onlineAccessValue = value
      this.updateHiddenSelect('ff-online_access', value)
    },
  },
}
</script>
