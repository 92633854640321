import { createVueApp } from '@/vue_components/create_vue_app'

let vueApp = null
const createWorkplacesCatalogApp = (el = '#vue_workplaces_catalog_root') => {
  if (!document.querySelector(el)) { return }

  if (vueApp?.$destroy) { vueApp.$destroy() }

  vueApp = createVueApp({
    el,
    name: 'WorkplacesCatalogApp',
    render: (h) => h('router-view'),
  })
}

$document.on('rez/workplaces', () => {
  createWorkplacesCatalogApp()
})
