import { createVueApp } from '@/vue_components/create_vue_app'

let vueApp = null

const clinicalGuidelinesCatalogApp = (el = '#vue-clinical-guidelines-catalog-root') => {
  if (vueApp?.$destroy) { vueApp.$destroy() }

  vueApp = createVueApp({
    el,
    name: 'ClinicalGuidelinesCatalogApp',
    render: (h) => h('router-view'),
  })
}

$document.on('rez/clinical_guidelines_catalog', () => clinicalGuidelinesCatalogApp())
