import { ICatalog } from '@/_declarations/ICatalog'
import {
  TradeAcquiringConfigurationModel,
} from '@/vue_apps/app_configuration/tradeAcquiring/classes/TradeAcquiringConfigurationModel'
import {
  TRADE_ACQUIRING_CONFIGURATION_BANK,
} from '@/vue_apps/app_configuration/tradeAcquiring/consts/tradeAcquiringConfigurationBank'

export class TradeAcquiringConfigurationLogic extends TradeAcquiringConfigurationModel {
  setTradeAcquiringBank (value: ICatalog<TRADE_ACQUIRING_CONFIGURATION_BANK>) {
    if (this.tradeAcquiringBank?.id === value.id) {
      super.setValue('tradeAcquiringBank', null)

      return
    }

    super.setValue('tradeAcquiringBank', value)
  }

  setAutoPrintingOfTradeAcquiringReceipts (value: boolean) {
    super.setValue('autoPrintingOfTradeAcquiringReceipts', value)

    if (value) { return }
    super.setValue('notPrintReceiptForSuccessfulAcquiringOperations', false)
  }
}
