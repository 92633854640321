<template>
  <div class="old-document-footer-app">
    <m-button
      v-if="hideSubmit"
      class="document-submit"
      template="save"
      :disabled="loading"
      @click="onSubmit"
    />

    <m-back-button
      size="small"
      class="mt-0"
      :text="t('cancel')"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MBackButton from '@/vue_present/_base/buttons/MButtonBack/MButtonBack.vue'
import { MDocumentPresenter } from '@/_api/MDocumentPresenter/MDocumentPresenter'
import { DOCUMENT_TYPES } from '@/vue_present/Reuse/DocumentTree/store/documentTypes'
import { OLD_DOCUMENT_FOOTER_MODES } from '@/vue_apps/Documents/OldDocumentFooter/store/oldDocumentFooterModes'
import {
  REUSABLE_DOCUMENTS_TREE_DOCUMENT_TYPES,
} from '@/vue_present/Reuse/DocumentTree/store/reusableDocumentsTreeDocumentTypes'
import { IDocumentsOwnerAttribute } from '@/_declarations/documents/IDocumentsOwnerAttribute'
import { IPubSubCreatedDocument } from '@/_declarations/pubSub/interfaces/IPubSubCreatedDocument'
import { PUB_SUB_EVENTS } from '@/_declarations/pubSub/pubSubEvents'
import { getDocumentsOwnersAttributes } from '@/vue_present/Reuse/DocumentTree/store/getDocumentsOwnersAttributes'
import { getDocumentOwnersFromParams } from '@/vue_apps/Documents/OldDocumentFooter/store/getDocumentOwnersFromParams'
import { formDataToObject } from '@/helpers/formDataToObject'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'

export default defineComponent({
  name: 'OldDocumentFooterApp',

  components: { MBackButton, MButton },

  mixins: [SpinnerHolder],

  data () {
    return {
      documentsPresenter: new MDocumentPresenter(),
      params: null,
    }
  },

  computed: {
    mode () {
      const { path } = this.$route

      if (path.endsWith(OLD_DOCUMENT_FOOTER_MODES.NEW)) {
        return OLD_DOCUMENT_FOOTER_MODES.NEW
      }

      if (path.endsWith(OLD_DOCUMENT_FOOTER_MODES.EDIT)) {
        return OLD_DOCUMENT_FOOTER_MODES.EDIT
      }

      return OLD_DOCUMENT_FOOTER_MODES.SHOW
    },

    documentType () {
      return this.params.get('fixed_doc_type')
        ? REUSABLE_DOCUMENTS_TREE_DOCUMENT_TYPES.FIXED
        : REUSABLE_DOCUMENTS_TREE_DOCUMENT_TYPES.DOCUMENT
    },

    hideSubmit () {
      return !this.params.get('hide_submit')
    },
  },

  created () {
    this.params = new URLSearchParams(window.location.search)
  },

  methods: {
    async onSubmit () {
      const documentsOwners = getDocumentsOwnersAttributes(
        DOCUMENT_TYPES.OLD_DOCUMENT,
        getDocumentOwnersFromParams()
      )

      const data = await this.withSpinnerSimple(this.submitDocument(documentsOwners))

      if (!data || !data.id) { return }

      if (this.mode === OLD_DOCUMENT_FOOTER_MODES.NEW) {
        const payload: IPubSubCreatedDocument = {
          documentId: data.id,
          documentType: DOCUMENT_TYPES.OLD_DOCUMENT,
          documentsOwners,
        }

        this.$pubSub.emit(PUB_SUB_EVENTS.CREATED_DOCUMENT, payload, true)
      }

      Turbolinks.visit(this.$routes.document_path(data.id))
    },

    submitDocument (documentsOwners: IDocumentsOwnerAttribute[]) {
      const documentHTML = document.querySelector('.fixed_doc_form_container').innerHTML

      switch (this.mode) {
        case OLD_DOCUMENT_FOOTER_MODES.NEW:
          return this.create(documentHTML, documentsOwners)
        case OLD_DOCUMENT_FOOTER_MODES.EDIT:
          return this.update(documentHTML)
      }
    },

    create (documentHTML: string, documentsOwners: IDocumentsOwnerAttribute[]) {
      switch (this.documentType) {
        case REUSABLE_DOCUMENTS_TREE_DOCUMENT_TYPES.FIXED:
          return this.createFixedDocument(documentHTML, documentsOwners)
      }
    },

    update (documentHTML: string) {
      const payload = {
        id: gon.page.params.id,
        attributes: {
          ...formDataToObject('.simple_form'),
          'document[data]': documentHTML,
        },
      }

      return this.documentsPresenter.update(payload)
    },

    createFixedDocument (documentHTML: string, documentsOwners: IDocumentsOwnerAttribute[]) {
      return this.documentsPresenter.createFixedDocument({
        clientId: this.params.get('client_id'),
        title: this.params.get('title'),
        data: documentHTML,
        documentsOwnersAttributes: documentsOwners,
      })
    },
  },
})
</script>
