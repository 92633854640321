<template>
  <el-menu
    class="m-menu"
    :class="{
      'm-menu-collapsed': isCollapsed
    }"
    mode="vertical"
    :router="router"
    :default-active="$route.path"
  >
    <m-menu-category
      v-for="(category, index) in menuCategories"
      :key="`menuCategory:${index}`"
      :menu-category="category"
      :show-label="showLabel"
      :is-collapsed="isCollapsed"
    />
  </el-menu>
</template>

<script>
import MMenuCategory from '@/vue_present/_base/MMenu/MMenuCategory'

export default {
  name: 'MMenu',

  components: { MMenuCategory },

  props: {
    router: Boolean,
    menuCategories: { type: Array, default: () => [] },
    isCollapsed: Boolean,
  },

  data () {
    return {
      showLabel: true,
    }
  },

  watch: {
    isCollapsed () {
      /**
       * Анимация с таймаутом необходима для нормального отображения заголовка при сворачивании/разворачивании
       */
      this.isCollapsed
        ? this.showLabel = false
        : setTimeout(() => { this.showLabel = true }, 100)
    },
  },
}
</script>
