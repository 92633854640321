import { createVueApp } from '@/vue_components/create_vue_app'
import UpdateTaxRatesModal from './UpdateTaxRatesModal.vue'

let vueApp = null

export const createUpdateTaxRatesModal = ({ modalData, config }, el = '#update_tax_rate_modal') => {
  if (vueApp?.$destroy) { vueApp.$destroy() }

  vueApp = createVueApp({
    el,
    name: 'UpdateTaxRatesModalRoot',
    render: (h, context) => h(UpdateTaxRatesModal, {
      props: {
        withEntryTypesUpdate: Boolean(config?.withEntryTypesUpdate),
      },

      on: {
        registerApi (api) {
          api.openModal(modalData)
        },
      },
    }),
  })
}
