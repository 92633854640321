import {
  getTradeAcquiringBankItem,
  TRADE_ACQUIRING_CONFIGURATION_NO_BANK,
} from '@/vue_apps/app_configuration/tradeAcquiring/consts/tradeAcquiringConfigurationItems'
import {
  TradeAcquiringConfigurationModel,
} from '@/vue_apps/app_configuration/tradeAcquiring/classes/TradeAcquiringConfigurationModel'
import {
  ITradeAcquiringConfigurationToClientDTO,
} from '@/vue_apps/app_configuration/tradeAcquiring/interfaces/ITradeAcquiringConfigurationToClientDTO'
import { orNull } from '@/_medods_standart_library/msl'

export const tradeAcquiringConfigurationAdapter = {
  toServer (data: TradeAcquiringConfigurationModel) {
    return {
      _method: 'patch',
      appConfiguration: {
        ...data,
        tradeAcquiringBank: data.tradeAcquiringBank?.id || TRADE_ACQUIRING_CONFIGURATION_NO_BANK,
      },
    }
  },

  toClient (data: ITradeAcquiringConfigurationToClientDTO) {
    return {
      ...data,
      tradeAcquiringBank: orNull(
        data.tradeAcquiringBank !== TRADE_ACQUIRING_CONFIGURATION_NO_BANK &&
        getTradeAcquiringBankItem(data.tradeAcquiringBank)
      ),
    }
  },
}
