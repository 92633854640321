import { Api } from '@/_api/decorators/api/Api'
import { ICRUDReturn } from '@/_api/decorators/api/interfaces/ICRUDReturn'
import { Callback } from '@/_api/decorators/callback'
import {
  TradeAcquiringConfigurationLogic,
} from '@/vue_apps/app_configuration/tradeAcquiring/classes/TradeAcquiringConfigurationLogic'
import {
  tradeAcquiringConfigurationAdapter,
} from '@/vue_apps/app_configuration/tradeAcquiring/api/tradeAcquiringConfigurationAdapter'
import {
  ITradeAcquiringConfigurationToClientDTO,
} from '@/vue_apps/app_configuration/tradeAcquiring/interfaces/ITradeAcquiringConfigurationToClientDTO'
import { IError } from '@/_declarations/IError'

export class TradeAcquiringConfigurationApi extends TradeAcquiringConfigurationLogic {
  @Callback('_fillProps')
  @Api.notify('fetchMessage', 'tradeAcquiringConfiguration', { success: false })
  @Api.clientAdapter(tradeAcquiringConfigurationAdapter)
  @Api.get(Routes.api_internal_configurations_acquiring_path)
  fetchConfiguration () {
    return {} as ICRUDReturn as Promise<ITradeAcquiringConfigurationToClientDTO | IError>
  }

  @Api.notify('submitMessage', 'configurations')
  @Api.post(Routes.app_configuration_path, { toFormData: true })
  @Api.serverAdapter(tradeAcquiringConfigurationAdapter)
  submit () {
    return {
      routeArgs: [gon.page.params.id],
      payload: this.getAttributes(),
    } as ICRUDReturn as Promise<void>
  }
}
