<template>
  <div
    v-loading="loading"
    class="trade-acquiring-configuration"
  >
    <!-- Банки -->
    <m-buttons-group
      :value="tradeAcquiringConfiguration.tradeAcquiringBank"
      :label="t$('bank')"
      :m-fixed-height="false"
      :items="banks"
      radio
      @change="tradeAcquiringConfiguration.setTradeAcquiringBank($event)"
    />

    <!-- Одновременная сверка итогов и отчет с гашением -->
    <m-switch
      :value="tradeAcquiringConfiguration.reconciliationTheResultsWithZMainReport"
      :active-text="t$('reconciliationTheResultsWithZMainReport')"
      :disabled="!tradeAcquiringConfiguration.tradeAcquiringBank"
      :question-mark-tooltip="t$('reconciliationTheResultsWithZMainReportTooltip')"
      @change="tradeAcquiringConfiguration.setValue('reconciliationTheResultsWithZMainReport', $event)"
    />

    <!-- Печать чеков на принтере фискального регистратора -->
    <m-switch
      :value="tradeAcquiringConfiguration.autoPrintingOfTradeAcquiringReceipts"
      :active-text="t$('autoPrintingOfTradeAcquiringReceipts')"
      :disabled="!tradeAcquiringConfiguration.tradeAcquiringBank"
      :question-mark-tooltip="t$('autoPrintingOfTradeAcquiringReceiptsTooltip')"
      @change="tradeAcquiringConfiguration.setAutoPrintingOfTradeAcquiringReceipts($event)"
    />

    <!-- Не печатать чеки для успешных операций -->
    <m-switch
      v-if="tradeAcquiringConfiguration.autoPrintingOfTradeAcquiringReceipts"
      :value="tradeAcquiringConfiguration.notPrintReceiptForSuccessfulAcquiringOperations"
      :active-text="t$('notPrintReceiptForSuccessfulAcquiringOperations')"
      :disabled="!tradeAcquiringConfiguration.tradeAcquiringBank"
      @change="tradeAcquiringConfiguration.setValue('notPrintReceiptForSuccessfulAcquiringOperations', $event)"
    />

    <m-button
      template="save"
      @click="tradeAcquiringConfiguration.submit()"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MSwitch from '@/vue_present/_base/inputs/MSwitch/MSwitch.vue'
import MButtonsGroup from '@/vue_present/_base/buttons/MButtonsGroup/MButtonsGroup.vue'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import {
  TradeAcquiringConfigurationApi,
} from '@/vue_apps/app_configuration/tradeAcquiring/classes/TradeAcquiringConfigurationApi'
import {
  TRADE_ACQUIRING_CONFIGURATION_ITEMS,
} from '@/vue_apps/app_configuration/tradeAcquiring/consts/tradeAcquiringConfigurationItems'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'

export default defineComponent({
  name: 'TradeAcquiringConfigurationApp',

  components: {
    MButtonsGroup,
    MSwitch,
    MButton,
  },

  mixins: [i18nScopeMixin, SpinnerHolder],

  data () {
    return {
      banks: TRADE_ACQUIRING_CONFIGURATION_ITEMS,
      tradeAcquiringConfiguration: new TradeAcquiringConfigurationApi(),
    }
  },

  created () {
    this.setI18nScope('tradeAcquiring')
    this.withSpinner(this.tradeAcquiringConfiguration.fetchConfiguration())
  },
})
</script>
