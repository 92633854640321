import { createUpdateTaxRatesModal } from '../UpdateTaxRatesModal'
import { TAX_RATES_SENDABLE_TO_DRIVER } from '../const/const'

const toggleTaxRateFieldWarningIcon = ({ event, warninIconElem }) => {
  const selectedTaxRate = event.target.value
  const isSelectedTaxRateSendableToDriver =
    TAX_RATES_SENDABLE_TO_DRIVER.includes(selectedTaxRate)

  !selectedTaxRate || isSelectedTaxRateSendableToDriver
    ? warninIconElem.hide()
    : warninIconElem.show()
}

PubSub.on(
  'entryTypeTaxRateFieldChange',
  (msg, data) => toggleTaxRateFieldWarningIcon(data)
)

PubSub.on(
  'openUpdateTaxRatesModal',
  (msg, data) => createUpdateTaxRatesModal(data)
)
