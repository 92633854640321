import { MSiSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiSchema'
import { MTableHeader } from '@/vue_present/_base/Tables/MTableGenerator/MTableHeader'
import { MTableSchema } from '@/vue_present/_base/Tables/MTableGenerator/MTableSchema'

export const getMeasureUnitsListSchema = (): MSiSchema => {
  const tableSchema = new MTableSchema({
    headers: {
      icon: new MTableHeader().addFixedWidth('40px'),
      title: new MTableHeader(),
    },
  })

  return new MSiSchema()
    .addCreateButton()
    .addPagination()
    .addSearch()
    .addTableSchema(tableSchema)
}

export const getMeasureUnitsListAddButtonConfig = () => ({
  tooltip: t('measureUnits.add'),
  icon: 'measureUnits',
  canManage: 'MeasureUnit',
})
