<template>
  <el-menu-item
    v-tooltip="menuItem.meta.tooltip"
    class="m-menu-item"
    :class="{ vertical, 'is-active': isActive }"
    :index="menuItem.path"
  >
    <div class="m-menu-item__icon-wrapper">
      <i
        class="m-menu-item__icon fad fa-fw"
        :class="`fa-${menuItem.meta.icon}`"
      />
    </div>

    <span
      v-if="showLabel || vertical"
      class="m-menu-item__label"
    >
      {{ menuItem.meta.label }}
    </span>
  </el-menu-item>
</template>

<script>
export default {
  name: 'MMenuItem',

  props: {
    menuItem: { type: Object, required: true },

    showLabel: Boolean,
    vertical: Boolean,
    isNestedPage: Boolean,
  },

  computed: {
    isActive () {
      if (!this.$route) { return }

      const routePath = this.$route.path
      const menuItemPath = this.menuItem.path

      return (routePath.startsWith(menuItemPath) && routePath[menuItemPath.length] === '/') || this.isNestedPage
    },
  },
}
</script>
