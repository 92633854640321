import { createVueApp } from '@/vue_components/create_vue_app'
import TradeAcquiringConfigurationApp
  from '@/vue_apps/app_configuration/tradeAcquiring/TradeAcquiringConfigurationApp.vue'

let vueApp = null
const createTradeAcquiringConfigurationApp = (el = '#vue_trade_acquiring_configuration_app') => {
  if (vueApp?.$destroy) { vueApp.$destroy() }

  vueApp = createVueApp({
    el,
    name: 'TradeAcquiringConfigurationAppRoot',
    render: (h) => h(TradeAcquiringConfigurationApp),
  })
}

$document.on('rez/app_configurations/edit', () => createTradeAcquiringConfigurationApp())
