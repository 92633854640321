import {
  acquiringRoute,
  expensesRoute,
  ordersRoute,
  paymentsRoute,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/router/financeModuleRoutes'
import { FINANCE_ROUTE_NAMES } from '@/vue_apps/FinanceModule/FinanceModuleIndex/router/routeNames'

export const navRoutes = () => Object.freeze([
  {
    items: [ordersRoute],
    vertical: true,
  },
  {
    items: Services.security.canViewPayment && [paymentsRoute],
    nestedRoutes: [FINANCE_ROUTE_NAMES.PAYMENT_SHOW, FINANCE_ROUTE_NAMES.PAYMENT_NEW],
    vertical: true,
  },
  {
    items: Services.security.canViewAcquiringTrade && [acquiringRoute],
    vertical: true,
  },
  {
    items: Services.security.canViewExpense && [expensesRoute],
    vertical: true,
  },
])
