<template>
  <div class="network-module">
    <component :is="currentPageComponent">
      <m-card v-if="isModuleEnabled">
        <div class="network-module__switch flex flex-column gap-indent">
          <m-switch
            v-if="isModuleEnabled"
            :value="logoutWhenIPChange"
            :active-text="t('network_vue.limit_by_ip')"
            @change="onLogoutWhenIPChange"
          />

          <div
            v-if="logoutWhenIPChange"
            class="flex gap-indent-small align-items-center"
          >
            <m-input
              v-tooltip="t('network_vue.checkInterval')"
              type="number"
              :min="1"
              :max="10"
              :value="ipCheckIntervalValue"
              :precision="0"
              :m-fixed-height="false"
              :label="t('network_vue.checkIntervalLabel')"
              full-width
              controls
              @change="onIPCheckIntervalChange"
            />
          </div>
        </div>
      </m-card>
      <m-button
        v-loading="isToggleProcessOn"
        :text="toggleModuleBtnParams.text"
        :button-style="toggleModuleBtnParams.style"
        :disabled="toggleModuleBtnParams.disabled"
        :icon="toggleModuleBtnParams.icon"
        type="primary"
        class="network-module__toggle-btn"
        @click="toggleModule"
      />
    </component>
  </div>
</template>

<script>
import ModuleDisabledPage from '@/vue_apps/app_configuration/network/pages/ModuleDisabledPage.vue'
import ModuleActivePage from '@/vue_apps/app_configuration/network/pages/ModuleActivePage.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { MNetworkPresenter } from '@/_api/MNetwork/MNetworkPresenter'
import MSwitch from '@/vue_present/_base/inputs/MSwitch/MSwitch.vue'
import MCard from '@/vue_present/_base/MCard/MCard.vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import { MAppConfigurationAPI } from '@/_api/MAppConfigurationAPI/MAppConfigurationAPI'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import { debounce } from 'lodash'

const NOT_ALLOWED_DISABLED_TOGGLE_BTN_CONFIG = Object.freeze({
  disabled: true,
  tooltip: t('whitelist.demomed_ban'),
})

export default {
  name: 'NetworkModule',

  components: {
    MInput,
    MIcon,
    MCard,
    MSwitch,
    ModuleDisabledPage,
    ModuleActivePage,
    MButton,
  },

  props: {
    moduleIsEnabledDefault: Boolean,
    isModuleAllowed: Boolean,
    configurationLimitByIp: Boolean,
    ipCheckInterval: { type: Number, default: 2 },
  },

  data () {
    return {
      isModuleEnabled: this.moduleIsEnabledDefault,
      isToggleProcessOn: false,
      logoutWhenIPChange: this.configurationLimitByIp,
      ipCheckIntervalValue: this.ipCheckInterval,

      updateIPCheckIntervalValue: debounce(this._updateIPCheckIntervalValue.bind(this), 1000),
    }
  },

  computed: {
    currentPageComponent () {
      return this.isModuleEnabled
        ? 'ModuleActivePage'
        : 'ModuleDisabledPage'
    },

    toggleModuleBtnParams () {
      return {
        style: this.isModuleEnabled
          ? 'round'
          : 'fill',

        icon: this.isModuleEnabled
          ? 'switchOn'
          : 'switchOff',

        disabled: this.isModuleAllowed
          ? false
          : NOT_ALLOWED_DISABLED_TOGGLE_BTN_CONFIG,

        text: t(`network_vue.module_is_enabled_${this.isModuleEnabled}`),
      }
    },
  },

  methods: {
    async toggleModule () {
      this.isToggleProcessOn = true

      const res = await new MNetworkPresenter().toggleModule(!this.isModuleEnabled)

      this.isToggleProcessOn = false

      if (res?.errors) { return }

      this.isModuleEnabled = res?.whiteListLoginEnabled ?? !this.isModuleEnabled
    },

    async onLogoutWhenIPChange (value) {
      this.logoutWhenIPChange = value
      const res = MAppConfigurationAPI.update({ limit_by_ip: value })
      if ('errors' in res) { this.logoutWhenIPChange = !value }
    },

    onIPCheckIntervalChange (value) {
      const oldValue = this.ipCheckIntervalValue
      this.ipCheckIntervalValue = value
      this.updateIPCheckIntervalValue(value, oldValue)
    },

    async _updateIPCheckIntervalValue (value, oldValue) {
      const res = MAppConfigurationAPI.update({ ip_check_interval: value })
      if ('errors' in res) { this.ipCheckIntervalValue = oldValue }
    },
  },
}
</script>
