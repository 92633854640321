import {
  WorkplacesConfigurationModel,
} from '@/vue_apps/app_configuration/workplaces/classes/WorkplacesConfigurationModel'
import {
  IWorkplacesConfigurationToClientDTO,
} from '@/vue_apps/app_configuration/workplaces/interfaces/IWorkplacesConfigurationToClientDTO'

export const workplacesConfigurationAdapter = {
  toServer (data: WorkplacesConfigurationModel) {
    return {
      _method: 'patch',
      appConfiguration: {
        printReceiptWithComplexMembers: data.printReceiptWithComplexMembers,
      },
    }
  },

  toClient (data: IWorkplacesConfigurationToClientDTO) {
    return {
      printReceiptWithComplexMembers: data.printReceiptWithComplexMembers,
    }
  },
}
