import { MListServiceItem } from '@/_api/_requests/MListServiceItem'
import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import { IMeasureUnit } from 'src/_declarations/IMeasureUnit'
import { checkMeasureUnitEditable } from '../const/helpers'

export class MeasureUnitsListItem extends MListServiceItem {
  id = null

  icon = null

  title = null

  constructor (data: IMeasureUnit) {
    super()

    this.id = data.id

    this.icon = new MTableCell()
      .addTypeIcon('measureUnits')

    checkMeasureUnitEditable(data)
      ? this.icon.addClasses('primary-font-color')
      : this.icon
        .addClasses('grey')
        .addTooltip(t('measureUnits.notEditableItemTitle'))

    this.title = new MTableCell(data.title)
      .addTooltip(true)
      .addCut()
  }
}
